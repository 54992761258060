import React from 'react';
import SEO from '../../components/en/seo/Seo';
import Header from "../../components/en/headers/Header";
import Footer from "../../components/en/layouts/Footer";
import PrivacyPolicyContent from "../../components/en/privacy-policy/PrivacyPolicyContent";
import PrivacyPolicyHeader from "../../components/en/privacy-policy/PrivacyPolicyHeader";

function PrivacyPolicy(props) {
    return (
        <div>
            <SEO
                title="Personal Data Protection Policy"
                description="We process your personal data in accordance with European legislation (Regulation (EU) 2016/697 on the protection of individuals with regard to the processing of personal data and on the flow of such data (hereinafter: the General Regulation)) and applicable legislation in the field of personal data protection. data (ZVOP-1, Ur. l. RS, no. 94/07)) and other legislation that gives us a legal basis for the processing of personal data."
            />
            <Header/>
            <PrivacyPolicyHeader/>
            <PrivacyPolicyContent/>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;