import React from 'react';

function PrivacyPolicyContent(props) {
    return (
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-wrapper">
                            <div className="inner privacy-content">

                                <p>The purpose of the Personal Data Protection Policy (hereinafter referred to as Policy), is to inform individuals, service users, employees and other persons (hereinafter referred to as individuals) who cooperate with the company Razvojni center Srca Slovenije, d.o.o. (hereinafter: the Organization) about the purposes and legal bases, security measures and the rights of individuals regarding the policy carried out by our organization.</p>

                                <p>
                                    <strong>We are aware of the importance of privacy and strive to protect your personal data in accordance with the applicable laws and regulations.</strong>
                                </p>

                                <p>
                                    We process your personal data in accordance with European legislation (Regulation (EU) 2016/697 on the protection of individuals with regard to the processing of personal data and on the flow of such data (hereinafter: the General Regulation)) and applicable legislation in the field of personal data protection. data (ZVOP-1, Ur. l. RS, no. 94/07)) and other legislation that gives us a legal basis for the processing of personal data.
                                </p>

                                <p>
                                    The policy contains information for individuals on how our organization, as a manager, processes personal data, which are received from individuals on legal bases, as described below.
                                </p>

                                <h2>Manager</h2>

                                <p>
                                    The manager of personal data is the organization:
                                    Razvojni center Srca Slovenije, d.o.o.,
                                    Kidričeva cesta 1, 1270 Litija,
                                    01 89 62 710,
                                    info@razvoj.si.

                                </p>

                                <h2>Authorized person</h2>

                                <p>
                                    In accordance with Article 37 of the General Regulation, we have appointed the following company as the data protection officer:
                                    Mitja Bratun,
                                    Razvojni center Srca Slovenije, d.o.o.,
                                    Kidričeva cesta 1, 1270 Litija,
                                    01 89 62 713,
                                    mitja.bratun@razvoj.si.
                                </p>

                                <h2>Personal data</h2>

                                <p>
                                    Personal data are any information relating to an identified or identifiable individual (hereinafter: the data subject); an identifiable individual is one who can be identified directly or indirectly, in particular by indicating an identifier such as name, identification number, location data, web identifier, or by indicating one or more specific factors such as physical, physiological, genetic , the mental, economic, cultural or social identity of that individual.
                                </p>

                                <h2>The purposes of processing personal data and the legal basis for the latter</h2>

                                <p>The organization collects and processes your personal data on the following legal bases:
                                    <ul>
                                        <li>processing is necessary to fulfill a legal obligation applicable to the maneger;</li>
                                        <li>processing is necessary for carrying out tasks of the public interest or in the exercise of official authority vested in the manager;</li>
                                        <li>processing is necessary for the performance of a contract to which the data subject is a party, or for the implementation of measures at the request of such an individual before the conclusion of the contract;</li>
                                        <li>the processing is necessary for legitimate interests pursued by the manager or a third party;</li>
                                        <li>
                                            the data subject has consented to the processing of his or hers personal data for one or more specific purposes;
                                        </li>
                                        <li>
                                            processing is necessary to protect the vital interests of the data subject or persons.
                                        </li>
                                    </ul>
                                </p>

                                <h2>
                                    Fulfillment of a legal obligation or performance of a task in the public interest
                                </h2>

                                <p>
                                    Based on legal provisions, the organization primarily processes data of its employees, which is enabled by labor legislation.
                                </p>

                                <p>
                                    Thus, on the basis of a legal obligation, the organization mainly processes the following types of personal data: name and surname, gender, date of birth, EMŠO / SSN (social securty number) , tax number, place, municipality and country of birth, citizenship and residence for the purpose of employment contract and obligations arising from it.

                                </p>

                                <p>Legal grounds for processing personal data of individuals are also also written in: the Public Sector Wage System Act, the Civil Servants Act, the Protection against Natural and Other Disasters Act...</p>

                                <p>
                                    On the basis of public interest the processing of personal data is also permissible in the organization but only in limited cases.
                                </p>

                                <h2>
                                    Contract implementation
                                </h2>

                                <p>
                                    If you sign a contract with the organization, it represents the legal basis for the processing of personal data. We may process your personal data for the purpose of concluding and implementing a contract, such as e.g. ticket sales, membership in the association, implementation of trainings, contract for the provision of services, etc.
                                </p>

                                <p>
                                    If the individual does not provide personal data, the organization cannot conclude a contract, nor can the organization perform a service or deliver goods or other products in accordance with the contract, because it does not have the necessary data.
                                </p>

                                <p>
                                    The organization may, on the basis of performing a lawful activities, inform individuals and users at their e-mail address about its services, events, education, offers and other content. An individual may at any time request the termination of such communication and processing of personal data and cancel the receipt of messages via the unsubscribe link in the received message, or as a request by e-mail to info@razvoj.si or by regular mail.
                                </p>


                                <h2>
                                    Legitimate interest
                                </h2>

                                <p>
                                    The exercise of the legal basis of a legitimate interest is otherwise limited to processing by public authorities in the performance of their tasks. However, the organization may also process personal data on the basis of a legitimate interest, which it may pursue to a limited extent.
                                </p>

                                <p>
                                    The latter is not permissible where such interests are outweighed by the interests or fundamental rights and freedoms of the data subject. Where a legitimate interest is exercised, the organization shall always make an assessment in accordance with the General Regulation.
                                </p>

                                <p>
                                    Thus, it can occasionally inform individuals about services, events, trainings, offers and other content via e-mail, telephone calls and regular mail An individual may at any time request the termination of such communication and processing of personal data and cancel the receipt of messages via the unsubscribe link in the received message, or as a request by e-mail to info@razvoj.si or by regular mail.
                                </p>

                                <h2>
                                    Consent-based processing
                                </h2>

                                <p>
                                    If the organization does not have statutory legal basis of the public tasks, or a contractual obligation or a legitimate interest, it may apply for the consent of the individual. Thus, it may also process certain personal data of an individual for the following purposes when the individual gives his or her consent:

                                    <ul>
                                        <li>residence address and e-mail address for information and communication purposes,</li>
                                        <li>tax number or EMŠO / SSN (social securty number) for the purposes of possible enforcement in case of default (eg. non-payment),</li>
                                        <li>photographs, videos and other content related to the individual (eg. recordings at public events) for the purpose of documenting activities and informing the public about the work and events in the organization;</li>
                                    </ul>
                                </p>

                                <p>
                                    If an individual gives consent for the processing of personal data and at some point no longer wishes to do so, he may request the termination of the processing of personal data by requesting an e-mail to info@razvoj.si or by regular mail.
                                </p>

                                <h2>
                                    Processing is necessary to protect the vital interests of the individual
                                </h2>

                                <p>
                                    The organization may process the data of the subject to the extent necessary to protect his or her vital interests. Thus, the organization can search for a personal document, check whether that person exists in the database, examine his anamnesis or contact his relatives for which the organization does not need his consent. This applies in cases where this is absolutely necessary to protect the vital interests of the individual.
                                </p>

                                <h2>
                                    Storage and erasing personal data
                                </h2>

                                <p>
                                    The organization will retain personal data only for as long as is necessary to achieve the purpose for which the personal data was collected and processed. If the organization processes the data in accordance with the law, the organization will keep it for the period it prescribes In doing so, some data is kept for the duration of the collaboration with the organization, and some data needs to be kept permanently.
                                </p>

                                <p>
                                    Personal data processed by the organization on the basis of a contractual relationship with an individual are kept by the organization for the period necessary for the performance of the contract and for 6 years after its termination, except in cases where there is a dispute between the individual and the organization. In this case, the organization shall keep the data for 5 years after the final decision of the court, arbitration or court settlement or, if there was no litigation, 5 years from the date of peaceful settlement of the dispute.
                                </p>

                                <p>
                                    Those personal data that the organization processes on the basis of the personal consent of the individual or a legitimate interest will be kept by the organization until the consent is revoked or until the data is deleted. Upon receipt of the revocation or request for erasing, the data shall be deleted within 15 days at the latest. The organization may also delete this data before revocation when the purpose of the processing of personal data has been achieved or if so provided by law.

                                </p>

                                <p>
                                    Exceptionally, the organization may reject a request for erasing on grounds set out in the General Regulation, such as: the exercise of the right to freedom of expression and information, compliance with a legal obligation to process data, public interest reasons, public interest archiving purposes, scientific or historical research purposes, or statistical purposes, implementation or defense of legal claims.
                                </p>

                                <p>
                                    After the retention period, the manager effectively or permanently deletes or anonymises the personal data so that they can no longer be linked to a specific individual.
                                </p>

                                <h3>
                                    Cookies
                                </h3>
                                <table className="mt-2 mb-5">
                                    <tr>
                                        <th>Cookie name</th>
                                        <th>Purpose</th>
                                        <th>Retention period</th>
                                    </tr>
                                    <tr>
                                        <td>_ga</td>
                                        <td>Used to distinguish users.</td>
                                        <td>2 years</td>
                                    </tr>
                                    <tr>
                                        <td>_gid</td>
                                        <td>Used to distinguish users.</td>
                                        <td>24 hours</td>
                                    </tr>
                                    <tr>
                                        <td>_gat</td>
                                        <td>Used to throttle request rate.</td>
                                        <td>1 minute</td>
                                    </tr>
                                    <tr>
                                        <td>c_user</td>
                                        <td>Identification of the user and for advertising and analytics purposes on Facebook.</td>
                                        <td>Not specified</td>
                                    </tr>
                                    <tr>
                                        <td>locale</td>
                                        <td>Storage of the selected user's localization (language).</td>
                                        <td>Not specified</td>
                                    </tr>
                                    <tr>
                                        <td>wd</td>
                                        <td>Optimization of the Facebook interface display.</td>
                                        <td>Not specified</td>
                                    </tr>
                                    <tr>
                                        <td>sb</td>
                                        <td>Advertising and tracking of users on Facebook.</td>
                                        <td>Not specified</td>
                                    </tr>
                                    <tr>
                                        <td>datr</td>
                                        <td>Recognition of the user and prevention of abuse.</td>
                                        <td>2 years</td>
                                    </tr>
                                    <tr>
                                        <td>xs</td>
                                        <td>Prevention of abuse and recognition of the user.</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>_gat_gtag_UA_XXX</td>
                                        <td>Used to throttle request rate and for analytics of website visits.</td>
                                        <td>1 minute</td>
                                    </tr>
                                    <tr>
                                        <td>ajs_user_id</td>
                                        <td>Used to distinguish between users and store information about user activity.</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>_ga_XXX</td>
                                        <td>Used to distinguish users and for analytics of website visits.</td>
                                        <td>2 years</td>
                                    </tr>
                                    <tr>
                                        <td>ajs_anonymous_id</td>
                                        <td>Used to distinguish between users if the user is not logged in to the website.</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>cookieconsent_status</td>
                                        <td>Used to store the user's consent regarding the use of cookies.</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>_gat</td>
                                        <td>Used to throttle request rate and for analytics of website visits.</td>
                                        <td>1 minute</td>
                                    </tr>
                                    <tr>
                                        <td>_fbp</td>
                                        <td>Used for advertising on Facebook and tracking of users.</td>
                                        <td>3 months</td>
                                    </tr>
                                </table>

                                <h2>
                                    Contractual processing of personal data and their export
                                </h2>

                                <p>
                                    The organization may entrust the contractual processor for individual processing of personal data on the basis of a contractual processing contract. Contractual processors may only process confidential data on behalf of the manager, within the limits of his authority, which is written in a written contract or other legal act and in accordance with the purposes defined in this privacy policy.
                                </p>

                                <p>
                                    The contractual processors with which the provider cooperates are mainly:
                                    <ul>
                                        <li>accounting services and other providers of legal and business advice;</li>
                                        <li>infrastructure maintainers;</li>
                                        <li>information system maintainers;</li>
                                    </ul>
                                </p>

                                <p>
                                    Under no circumstances will the organization provide personal data to unauthorized third parties.
                                </p>

                                <p>
                                    Contractual processors may process personal data only in accordance with the instructions of the organization and may not use personal data for any other purposes.
                                </p>

                                <p>
                                    As a manager and its employees, the organization does not export personal data to third countries (outside the European Economic Area – EU member states and Iceland, Norway and Liechtenstein) and to international organizations, except in the US, with relations with US contract processors on the basis of standard contractual clauses (standard contracts adopted by the European Commission) and / or binding business rules (adopted by the organization and approved by the EU supervisory authorities).“
                                </p>

                                <h3>
                                    Data security and accuracy
                                </h3>

                                <p>
                                    The organization takes care of information and infrastructure security (premises and application system software). Our information system is protected by, among other things, antivirus programs and a firewall. We have put in place appropriate organizational and technical security measures designed to protect your personal data from accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access, and other illegal and unauthorized forms of processing. In the case of the transmission of special types of personal data, we provide them in encrypted form and protected by a password.
                                </p>

                                <p>
                                    You are solely responsible for providing us with your personal data sefely and that the information provided is accurate and credible. We will do our best to keep your personal data accurate and, if necessary, updated, and we may contact you from time to time to confirm the accuracy of it.
                                </p>

                                <h3>
                                    Individual rights regarding data processing
                                </h3>

                                <p>
                                    According to the General Regulation, an individual has the following rights:

                                    <ul>
                                        <li>You may request information about whether we have personal information about you and, if so, what information we hold and on what basis we hold it and why we use it.</li>
                                        <li>You may request access to your personal data, which allows you to receive a copy of the personal information we hold about you and verify that we are processing it legally.</li>
                                        <li>You may request corrections of personal data, such as the correction of incomplete or inaccurate personal information.</li>
                                        <li>You may request erasing of your personal data when there is no reason for further processing or when you exercise your right to object to further processing.</li>
                                        <li>You object to the further processing of personal data where we rely on a legitimate business interest (even in the case of a legitimate interest of a third party) when there are reasons related to your particular situation; notwithstanding the provisions of the previous sentence, you have the right to object at any time if we process your personal data for the purposes of marketing.</li>
                                        <li>
                                            You may request restrictions on the processing of your personal data, which means suspension of the processing of personal data, for example, if you want to determine their accuracy or check the reasons for their further processing.
                                        </li>

                                        <li>
                                            You may request the transfer of your personal data in a structured electronic form to another manager, if that is possible and feasible.
                                        </li>
                                        <li>
                                            You may revoke the consent you have given to the collection, processing and transfer of your personal data for a specific purpose; upon confirmation that you have withdrawn your consent, we will stop processing your personal data for the purposes you originally accepted, unless we have other legal grounds.
                                        </li>
                                    </ul>
                                </p>

                                <p>
                                    If you wish to exercise any of the aforementioned rights, send a request by e-mail to info@razvoj.si or by regular mail to the address of the organization.
                                </p>

                                <p>
                                    Access to your personal data and asserted rights is free of charge. However, we may charge a reasonable fee if the data subject’s request is manifestly unfounded or excessive, especially if its repeated. In this case, we can also reject the request.
                                </p>

                                <p>
                                    In case of exercising these rights we will need to obtain certain information to help us to verify your identity, which is only a security measure to ensure that personal information is not disclosed to unauthorized parties.
                                </p>

                                <p>
                                    In exercising the rights under this title you can also use a form of the Information Commissioner, which is available on their website.
                                </p>
                                <p>
                                    Website is available <a href=" https://www.iprs.si/fileadmin/user_upload/doc/obrazci/ZVOP/Zahteva_za_seznanitev_z_lastnimi_osebnimi_podatki__Obrazec_SLOP_.doc" target="_blank">here.</a>
                                </p>

                                <p>
                                    In case that you believe that your rights have been violated, you can contact the supervision authority or Information Commissioner. Website is available <a href="https://www.ip-rs.si/zakonodaja/reformaevropskega-zakonodajnega-okvira-za-varstvo-osebnih-podatkov/kljucna-podrocja-uredbe/prijavakrsitev/" target="_blank">here.</a>
                                </p>

                                <p>
                                    If you have any questions regarding the processing of your personal data, you can always contact us.
                                </p>

                                <h2>
                                    Post changes
                                </h2>

                                <p>
                                    Any changes in personal data protection policy will be posted on our website. By using the website, the individual confirms that he / she accepts and agrees with the entire content of this personal data protection policy.
                                </p>

                                <p>The personal data protection policy was adopted by the responsible person of the organization on January 1, 2022.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyContent;
